$red: #e91a50;
$green: #56CC9D;
$blue: #007bff;
$yellow: #FFCE67;
$zumzi: #0367BF;
$orange: #EE7814;

$grey: #C4C4C4;
$grey-light: #FAFAFA;
$grey-dark: #575757;
$blue: #0367BF;
$blue-light: #ECF2FA;
$white: #FFF;
$black: #000;

$brand: #08306B;
$brand-derivate: #041E41;
$brand-light: #233C9A;

$brand-secondary: #FABA0B;
$brand-secondary-derivate: #F9E63B;
$brand-secondary-dark: #ee7814;
