@import "colors";
$fontSize: 16px;
$padding: 8px 16px;

.input-primary {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: $padding;
  background: $grey-light;
  color: $brand;
  font-size: $fontSize;
}
.input-primary::placeholder {
  color: $brand;
  padding: $padding;
  font-size: $fontSize;
}

// input text
.input-text {
  background: $grey-light;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)!important;
  border-radius: 8px!important;
  background-clip: unset!important;

  &:focus {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)!important;
    border-radius: 8px!important;
  }
}

.input-group-flex {
  flex-wrap: nowrap!important;
  flex-direction: row;
  .my-input-control {
    position: relative;
    width: 100%;
  }
  @media (max-width: 991px) {
    & {
      flex-direction: column;
    }
  }
}

// checkbox
.form-check-input[type=checkbox] {
  background-color: transparent !important;
  border: 1.5px solid #FFFFFF;
  border-radius: 4px;

  &:focus, &:checked {
    background-color: transparent !important;
    border: 1.5px solid #FFFFFF!important;
    box-shadow: none!important;
  }

  &.dark {
    border: 1.5px solid $brand!important;
  }
}

// label
.form-label {
  font-weight: 600;
  font-size: 16px;
}

.invalid-feedback {
  text-align: left;
  color: $red!important;
}
.input-icon {
  position: absolute;
  right: 1rem;
  top: 10px;
  font-size: 18px;
  z-index: 3 !important;
}
.input-right-text {
  position: absolute;
  right: 1rem;
  top: 8px;
  font-size: 16px;
  z-index: 3 !important;
  color: $brand;
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: $white !important;
}
.form-control:focus{
}
.form-control.is-invalid {
  border-color: $red!important;
}
.padding-icon-right {
  padding-right: 2.25rem!important;
}
.padding-icon-right.form-control.is-invalid{
  background-image: none!important;
}

.input-medium {
  width: 20rem;
}
.input-small {
  width: 16rem;
}
.input-tiny {
  width: 7.5rem;
}
.input-group-save-button {
  padding-top: 2px;
  display: flex;
  svg {
    margin-top: 10px;
  }
}
@media (max-width: 991px) {
  .input-small, .input-medium {
    width: 100%;
  }
  .input-tiny {
    width: 50%;
  }
  .input-group-save-button {
    padding-top: 1rem;
  }
}

// form select
.form-select {
  border-radius: 8px;
  height: calc(1.5em + 0.75rem + 2px);
  min-width: 4rem;
}

.padding-icon-right {
  padding-right: 2.25rem!important;
}
.padding-icon-right.form-control.is-invalid{
  background-image: none!important;
}

.input-right-text {
  position: absolute;
  right: 1rem;
  top: 8px;
  font-size: 16px;
  z-index: 3 !important;
  color: $brand;
}